import { render, staticRenderFns } from "./QuizSlide.vue?vue&type=template&id=9bd5056a&scoped=true&"
import script from "./QuizSlide.vue?vue&type=script&lang=js&"
export * from "./QuizSlide.vue?vue&type=script&lang=js&"
import style0 from "./QuizSlide.vue?vue&type=style&index=0&id=9bd5056a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bd5056a",
  null
  
)

export default component.exports