<template>
  <div v-if="isBackground" class="lazy" :data-bg="loadSrc">
    <slot />
  </div>
  <img v-else class="lazy" :data-src="loadSrc" />
</template>

<script>
import LazyLoad from "vanilla-lazyload";

// Если нет инстанса ленивой загрузки то создаем его
// TODO: Попробовать убрать инстанс через document
if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad();
}

export default {
  name: "LazyImg",

  props: {
    // Включен Cloudflare
    enableCloudflare: {
      type: Boolean,
      default: false,
    },
    /**
     * Передаваемые параметры изображения.
     *
     * Документация https://developers.cloudflare.com/images/image-resizing/url-format
     * TODO: Возможно вынести в отдельную модель
     */
    cloudflareOptions: {
      type: Object,
      default: () => ({
        width: null,
        height: null,
        dpr: null,
        fit: "",
        gravity: "",
        quality: null,
        format: "",
        anim: false,
        sharpen: null,
        blur: null,
        onerror: "",
        metadata: "",
      }),
    },
    // Путь до изображения
    src: String,
    // Загружать ли изображения на задний фон
    isBackground: Boolean,
  },

  computed: {
    // Список параметров в виде строки через запятую
    imgParams() {
      return Object.entries(this.cloudflareOptions).reduce((str, [key, val]) => {
        const t = val ? `${key}=${val},` : "";
        return `${str}${t}`;
      }, "");
    },
    // Ссылка на подгружаемое изображение
    loadSrc() {
      return this.enableCloudflare && this.src
        ? `https://presentations.whenspeak.ru/cdn-cgi/image/${this.imgParams}/${this.src}`
        : this.src;
    },
  },

  // Обновляем изображение при монтировании компонента
  mounted() {
    document.lazyLoadInstance.update();
  },
  // Обновляем изображение при обновлении компонента
  updated() {
    document.lazyLoadInstance.update();
  },
};
</script>
