<template>
  <div class="presentation-skin">
    <div
      v-show="!$fullscreen.isFullscreen && fullScreenAvailable"
      class="presentation-skin__fullscreen"
      @click="enterToFullscreen"
    >
      <i class="presentation-skin__fullscreen-icon icon-size-fullscreen" />
    </div>
    <div
      v-if="pulseAvailable"
      v-show="!$fullscreen.isFullscreen"
      class="presentation-skin__line"
      :class="{ 'presentation-skin__line--selected': statusSelected }"
    >
      <pulse-icon-item
        v-for="icon in ['like', 'unclear', 'dislike']"
        :key="icon"
        :icon="icon"
        :status="status"
        @like="handleLike"
      ></pulse-icon-item>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { auth, presentation, room, user } from "@/store/modules/store.namespaces";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDE_ID,
} from "@/store/modules/presentation/getter-types";
import { LIKE_PRESENTATION, LIKE_SLIDE } from "@/store/modules/user/action-types";

import PulseIconItem from "./PulseIconItem";

export default {
  name: "PresentationSkin",
  components: {
    PulseIconItem,
  },
  computed: {
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
      currentPresentationSlideID: CURRENT_PRESENTATION_SLIDE_ID,
      currentPresentationSlide: CURRENT_PRESENTATION_SLIDE,
    }),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapState(auth, ["user"]),
    ...mapState(room, {
      offlineMode: state => state.roomInfo.Online_room === 0,
    }),
    isOfflinePresentation() {
      return this.currentPresentation && this.currentPresentation.type === "offline";
    },
    isOnlinePresentation() {
      return this.currentPresentation.type !== "offline";
    },
    presentationId() {
      return this.isOnlinePresentation
        ? this.currentPresentationSlideID
        : this.currentPresentation.id;
    },
    likeType() {
      return this.isOnlinePresentation ? this.user.likedSlides : this.user.likedPresentations;
    },
    status() {
      const statusObj = {
        like: null,
        tightened: null,
        unclear: null,
        dislike: null,
      };
      if (!this.currentPresentation || !this.likeType || !this.likeType[this.presentationId])
        return statusObj;

      this.likeType[this.presentationId].forEach(type => {
        statusObj[type] = true;
      }, statusObj);

      return statusObj;
    },
    pulseAvailable() {
      return (
        this.availableRoomModules.pulse &&
        !["results", "test", "video"].includes(this.currentPresentationSlide?.type)
      );
    },
    fullScreenAvailable() {
      return !["results", "test", "video"].includes(this.currentPresentationSlide?.type);
    },
    statusSelected() {
      return Object.values(this.status).some(status => !!status);
    },
  },
  methods: {
    ...mapActions(user, {
      likePresentation: LIKE_PRESENTATION,
      likeSlide: LIKE_SLIDE,
    }),
    handleLike(type) {
      this.isOfflinePresentation ? this.likePresentation(type) : this.likeSlide(type);
    },
    enterToFullscreen() {
      this.$fullscreen.enter("presentation");
    },
  },
};
</script>

<style scoped lang="less">
.presentation-skin {
  display: none;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    background: none;

    &__fullscreen {
      position: absolute;
      right: 13px;
      bottom: 8px;
      width: 35px;
      height: 35px;
      color: #000;
      cursor: pointer;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

      &-icon {
        position: absolute;
        top: 9px;
        left: 9px;
        display: block;
        width: 17px;
        height: 17px;
        font-size: 17px;
        line-height: 1;
      }
    }

    &__line {
      position: absolute;
      bottom: 8px;
      left: 8px;
      z-index: 1;

      &--selected ::v-deep .emotion {
        display: inline-block;
        width: 25px;
        height: 25px;

        &--active {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
</style>
