<template>
  <div class="MediaPlayerImage">
    <transition>
      <img
        v-show="isLoaded"
        class="MediaPlayerImage__image"
        :src="imageSrc"
        @load="loaded"
      />
    </transition>
  </div>
</template>
<script>
export default {
  name: "MediaPlayerImage",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageSrc: this.url,
      isLoaded: false,
    };
  },
  mounted() {
    this.loadImg();
  },
  methods: {
    loadImg() {
      this.isLoaded = false;
    },
    loaded() {
      this.isLoaded = true;
    },
  },
};
</script>
<style lang="less" scoped>
.MediaPlayerImage {
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  &__image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 1));
  }
}
</style>
