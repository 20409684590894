<template>
  <div :class="isActive && !pending ? 'polls-result' : 'container'">
    <template v-if="isActive && !pending">
      <div v-if="results.title" class="polls-result__title">
        {{ results.title }}
      </div>
      <template v-for="item in pollQuestions">
        <poll-score-item
          v-if="item.poll_type === pollType.CLOSED"
          :key="item.id"
          :poll="item"
          :fillHeight="false"
          :poll-chain="pollChain"
          class="polls-result__poll-score"
        />
        <word-cloud
          v-if="item.props.poll_type === pollType.WORD_CLOUD"
          :key="item.id"
          :chart="item"
          class="polls-result__word-cloud"
          :class="{ 'polls-result__word-cloud--toll': !onlyOnePollQuestion }"
        />
        <rating-result
          v-if="item.poll_type === pollType.RATING"
          :key="item.id"
          :chart="item"
          :is-voting="false"
          class="polls-result__rating-result"
        />
      </template>
    </template>
    <ws-icon v-else class="container__icon" :class="iconClasses">poll</ws-icon>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { polls } from "@/store/modules/store.namespaces";
import { interactive, presentation } from "@/constants/polls/polls-module-types";
import { SHOW_POLL_SCORE } from "@/store/modules/polls/action-types";
import SlideMixin from "@/components/event/room/Presentation/components/presentation-slider/presentation-slides/SlideMixin.vue";
import PollScoreItem from "@/components/common/polls/components/PollScoresItem.vue";
import WordCloud from "@/components/common/diagrams/WordCloud.vue";
import RatingResult from "@/components/common/diagrams/Rating/Rating.vue";
import { PollType } from "@/constants/polls/poll-types";

export default {
  name: "TestSlideResult",
  components: {
    WordCloud,
    RatingResult,
    PollScoreItem,
  },
  mixins: [SlideMixin],
  data() {
    return {
      pending: true,
      // Параметр с типами вопроса
      pollType: PollType,
    };
  },
  computed: {
    ...mapState(`${polls}/${presentation}`, {
      pollScore: "chainScore",
    }),
    ...mapState(`${polls}/${interactive}`, {
      poll: "activePoll",
    }),
    pollChain() {
      return this.$store.state.polls;
    },
    results() {
      return this.pollScore || {};
    },
    pollQuestions() {
      if (this.results.questions && this.results.questions.length >= 1) {
        return this.results.questions;
      }
      return null;
    },
    onlyOnePollQuestion() {
      return this.pollQuestions && this.pollQuestions.length === 1;
    },
    request() {
      return {
        type: presentation,
        payload: this.slide.test_info,
        isScreen: true,
      };
    },
  },
  watch: {
    "slide.test_id": function() {
      this.showPollScore(this.request);
    },
  },
  async mounted() {
    await this.showPollScore(this.request);
    if (this.pending) {
      setTimeout(() => {
        this.pending = !this.pending;
      }, 220);
    }
  },
  methods: {
    ...mapActions(polls, {
      showPollScore: SHOW_POLL_SCORE,
    }),
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  height: inherit;
  text-align: center;
  font-size: 30px;
  overflow: auto;

  &__icon {
    font-size: 60px;
    color: #adc7db;

    &--size-s {
      font-size: 24px;
    }
  }
}

.polls-result {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  &__title {
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
  }

  &__word-cloud {
    flex: 1 0 auto;

    &--toll {
      height: 70vh;
    }
  }

  &__rating-result {
    flex: 1 0 auto;
  }

  &__poll-score {
    flex: 1 0 auto;
  }
}
</style>
