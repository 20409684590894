<script>
export default {
  name: "slidesHorizontalScrollMixin",
  data() {
    return {
      scrolling: false,
      sliderPosition: null,
    };
  },
  methods: {
    startScroll(event) {
      this.scrolling = true;
      this.sliderPosition = event.clientX;
    },
    stopScroll() {
      this.scrolling = false;
      this.sliderPosition = null;
    },
    stopScrollMouseOut(event) {
      try {
        if (!this.$refs.slides.contains(event.relatedTarget)) {
          this.stopScroll(event);
        }
      } catch (e) {
        throw new Error("refs not setted, please set refs on dom element");
      }
    },
    moveSlider(event) {
      try {
        if (this.scrolling) {
          this.$refs.slides.scrollLeft -= event.clientX - this.sliderPosition;
          this.sliderPosition = event.clientX;
        }
      } catch (e) {
        throw new Error("refs not setted, please set refs on dom element");
      }
    },
  },
};
</script>
