/**
 Logitech R700 presenter

 @see http://weibeld.net/mac/remap-presenter-keys.html
 Next	      Page Down                           (34)
 Previous     Page Up                             (33)
 Start/Stop   Alternating between F5 and Esc      (116/27)
 Hide	      . (period)                          (46)
 */

export const REMOTE_CONTROL_MEDIA_KEYS = {
  START: 116,
  STOP: 27,
};

export const MEDIA_PLAYER_EVENTS = {
  ERROR: "ERROR",
  PLAY: "PLAY",
  CHANGE: "CHANGE",
  READY: "READY",
  STOP: "STOP",
  MUTE: "MUTE",
  UNMUTE: "UNMUTE",
  PAUSED: "PAUSED",
  ENDED: "ENDED",
  PAGE_FOCUS: "PAGE_FOCUS",
  PAGE_BLUR: "PAGE_BLUR",
};

export const isImageUrl = (() => {
  const RE_IS_IMAGE = /\.(?:jpg|gif|png|jpeg|gif|tiff|svg)$/i;
  return url => RE_IS_IMAGE.test(url);
})();

export const isYoutubeUrl = (() => {
  const RE_IS_YOUTUBE = /youtu\.?be/i;
  return url => RE_IS_YOUTUBE.test(url);
})();
export const isVimeoUrl = (() => {
  const RE_IS_VIMEO = /vimeo\.?com/i;
  return url => RE_IS_VIMEO.test(url);
})();

export const getYoutubeVideoId = (() => {
  const RE_PATTERNS = [
    /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
    /\?v=([^#\&\?]{11})/, // ?v=<id>
    /\&v=([^#\&\?]{11})/, // &v=<id>
    /embed\/([^#\&\?]{11})/, // embed/<id>
    /\/v\/([^#\&\?]{11})/, // /v/<id>
  ];
  return url => {
    if (isYoutubeUrl(url)) {
      for (let i = 0; i < RE_PATTERNS.length; ++i) {
        if (RE_PATTERNS[i].test(url)) {
          return RE_PATTERNS[i].exec(url)[1];
        }
      }
    }
    return null;
  };
})();

export const getVimeoVideoId = (() => {
  const RE_PATTERNS = [
    /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
    /\?v=([^#\&\?]{11})/, // ?v=<id>
    /\&v=([^#\&\?]{11})/, // &v=<id>
    /embed\/([^#\&\?]{11})/, // embed/<id>
    /\/v\/([^#\&\?]{11})/, // /v/<id>
  ];
  return url => {
    if (isVimeoUrl(url)) {
      for (let i = 0; i < RE_PATTERNS.length; ++i) {
        if (RE_PATTERNS[i].test(url)) {
          return RE_PATTERNS[i].exec(url)[1];
        }
      }
    }
    return null;
  };
})();

export default {};
