import { render, staticRenderFns } from "./MediaPlayer.vue?vue&type=template&id=972ae046&scoped=true&"
import script from "./MediaPlayer.vue?vue&type=script&lang=js&"
export * from "./MediaPlayer.vue?vue&type=script&lang=js&"
import style0 from "./MediaPlayer.vue?vue&type=style&index=0&id=972ae046&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "972ae046",
  null
  
)

export default component.exports