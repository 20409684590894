<template>
  <div class="buttons-wrapper">
    <mq-layout v-if="videoSrc" class="link-at-top d-link" :mq="$mqMobileKeys">
      <a :href="videoSrc" class="external-video" target="_blank" @click="logExternalVideo">
        {{ $t("PresentationButton.externalVideo") }}</a
      >
    </mq-layout>
    <div class="presentation-buttons" @click="onButtonClick($event)">
      <presentation-button
        v-if="!$fullscreen.isFullscreen"
        data-type="fullscreen"
        mr="7px"
        :active="false"
        icon="icon-size-fullscreen"
        :color="baseColor"
      />
      <mq-layout v-if="videoSrc" class="link-in-buttons d-link" mq="tablet+">
        <a :href="videoSrc" class="external-video" target="_blank" @click="logExternalVideo">{{
          $t("PresentationButton.externalVideo")
        }}</a>
      </mq-layout>
      <presentation-button
        v-if="pulseAvailable"
        data-type="like"
        ml="auto"
        mr="0"
        :active="status.like"
        :title="$t('PresentationButton.like')"
        icon="icon-like"
        color="#7ed321"
      />
      <presentation-button
        v-if="pulseAvailable"
        data-type="unclear"
        ml="8px"
        mr="0"
        :active="status.unclear"
        :title="$t('PresentationButton.unclear')"
        icon="icon-bubble"
        color="#e87d2a"
      />
      <presentation-button
        v-if="pulseAvailable"
        data-type="dislike"
        ml="8px"
        mr="0"
        :active="status.dislike"
        :title="$t('PresentationButton.dislike')"
        icon="icon-dislike"
        color="#bd1414"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { auth, presentation, room, user } from "@/store/modules/store.namespaces";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDE_ID,
} from "@/store/modules/presentation/getter-types";
import { LIKE_PRESENTATION, LIKE_SLIDE } from "@/store/modules/user/action-types";
import PresentationButton from "./PresentationButton";

const likeTypes = ["like", "tightened", "unclear", "dislike"];
const FULLSCREEN_TYPE = "fullscreen";

export default {
  components: {
    PresentationButton,
  },
  props: {
    videoSrc: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
      currentPresentationSlideID: CURRENT_PRESENTATION_SLIDE_ID,
    }),
    ...mapState(auth, ["user"]),
    ...mapState(room, {
      room: "roomNumber",
      offlineMode: state => state.roomInfo.Online_room === 0,
      baseColor: state => state.styleColors.baseColor,
    }),
    isOfflinePresentation() {
      return this.currentPresentation && this.currentPresentation.type === "offline";
    },
    pulseAvailable() {
      return this.availableRoomModules.pulse;
    },
    status() {
      const statusObj = {
        like: null,
        tightened: null,
        unclear: null,
        dislike: null,
      };
      if (!this.currentPresentation) {
        return statusObj;
      }
      const online = this.currentPresentation.type !== "offline";
      const likesObj = online ? this.user.likedSlides : this.user.likedPresentations;

      const id = online ? this.currentPresentationSlideID : this.currentPresentation.id;
      if (!likesObj || !likesObj[id]) {
        return statusObj;
      }

      likesObj[id].forEach(type => {
        statusObj[type] = true;
      }, statusObj);

      return statusObj;
    },
  },
  methods: {
    ...mapActions(user, {
      likePresentation: LIKE_PRESENTATION,
      likeSlide: LIKE_SLIDE,
    }),
    onButtonClick(event) {
      const target = event.target.closest(".presentation-buttons [data-type]");

      if (!target) {
        return;
      }

      const { type } = target.dataset;
      const presentation = this.currentPresentation;
      const action = presentation.type === "offline" ? "likePresentation" : "likeSlide";

      if (likeTypes.includes(type)) {
        this[action](type);
        return;
      }

      if (type === FULLSCREEN_TYPE) {
        this.$fullscreen.enter("presentation");
      }
    },
    logExternalVideo() {
      this.$loggers.$video.sendLog(
        this.$loggers.$video.getPayload({
          user: this.user,
          roomNumber: this.room,
          presentationId: this.currentPresentation.id,
          event: this.$loggers.$video.Events.ChangePlayer,
        }),
      );
    },
  },
};
</script>

<style scoped lang="less">
a.external-video {
  display: inline-block;
  color: var(--base-color);
}

.buttons-wrapper {
  padding: 8px;
  background: #fff;
  border-top: solid 1px #ebebeb;

  .d-link {
    flex-grow: 1;
    align-self: center;
    margin: 0 auto;
    text-align: center;
  }

  .link-at-top {
    padding: 0 11px 11px;
  }

  .link-in-buttons {
    padding: 0 11px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: none;
  }

  @media (max-width: 768px) {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.presentation-buttons {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  transition: width 0.3s ease;

  &--expanded {
    width: 0;
    opacity: 0;
  }
}
</style>
