<template>
  <div class="presentations--list">
    <div v-for="presentation in presentations" :key="presentation.id" class="presentation--tile">
      <div class="presentation--tile__content" @click="runPresentation(presentation)">
        <div class="presentation--tile_top"></div>
        <div class="presentation--tile__icon">
          <span class="icon-control-play"></span>
        </div>
        <div class="presentation--tile__desc">{{ presentation.name }}</div>
      </div>
      <img
        class="presentation--tile__image"
        :src="buildImgSrc(presentation.slides[1])"
        alt="Slide"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { IMAGE_TYPE } from "@/constants/presentation/presentation-const";
import { presentation } from "@/store/modules/store.namespaces";
import { SET_PRESENTATION_INFO } from "@/store/modules/presentation/mutation-types";
import { CURRENT_PRESENTATION } from "@/store/modules/presentation/getter-types";
import { makeUrl } from "@/utils";

export default {
  name: "PresentationList",
  computed: {
    ...mapState(presentation, ["presentations"]),
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
    }),
  },
  methods: {
    ...mapMutations(presentation, {
      setPresentationInfo: SET_PRESENTATION_INFO,
    }),
    buildImgSrc(slide) {
      if (slide.type === IMAGE_TYPE.extimg) {
        return slide.path;
      }
      return makeUrl(slide.path);
    },
    runPresentation(selectedPresentation) {
      this.setPresentationInfo({
        presentationId: selectedPresentation.id,
        slideIndex: 1,
        userSelectedSlideIndex: 1,
      });
      this.$router.push({ query: { slide: 1, presentation: selectedPresentation.id } });
    },
  },
};
</script>

<style scoped lang="less">
.presentations--list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  padding: 10px;
  overflow: auto;
}

.presentation--tile {
  position: relative;
  height: auto;
  margin: 5px;
}

.presentation--tile__image {
  display: block;
  width: 300px;
  height: 180px;
  border-radius: 5px;
}

.icon-control-play {
  margin: auto;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 25px solid white;
}

.presentation--tile__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.presentation--tile__icon {
  display: flex;
  align-content: center;
  width: 50px;
  height: 50px;
  padding-left: 5px;
  color: white;
  background-color: var(--base-color);
  border-radius: 50%;
}

.presentation--tile__desc {
  width: 250px;
  max-height: 50px;
  margin: auto;
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.presentation--tile_top {
  margin: auto;
}
</style>
