<script>
import { mapGetters, mapState } from "vuex";
import { IMAGE_TYPE } from "@/constants/presentation/presentation-const";
import { polls, presentation } from "@/store/modules/store.namespaces";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDE,
} from "@/store/modules/presentation/getter-types";
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import { makeUrl } from "@/utils";

const DEFAULT_SLIDE_IMG_SIZE = "/resize_1920_1080_75";

export default {
  computed: {
    ...mapGetters(presentation, {
      currentPresentationSlide: CURRENT_PRESENTATION_SLIDE,
      currentPresentation: CURRENT_PRESENTATION,
    }),
    ...mapState(polls, ["tests"]),
    currentSlide() {
      return this.currentPresentationSlide;
    },
    videoSlide() {
      const slide = this.currentSlide;
      if (!slide) {
        return;
      }

      return slide.type === SLIDE_TYPE.video ? slide : null;
    },
    slideSrc() {
      const slide = this.currentSlide;
      if (!slide) {
        return null;
      }
      // TODO: Отключение ресайзера
      // if (slide.type === IMAGE_TYPE.extimg) {
      //   return `${slide.path}${DEFAULT_SLIDE_IMG_SIZE}`;
      // }
      return makeUrl(slide.path);
    },
    videoSrc() {
      if (this.currentPresentation && this.currentPresentation.type === "offline") {
        return this.currentPresentation.video ? this.currentPresentation.video : "";
      }
      return "";
    },
    hasVideoSrc() {
      return Boolean(this.videoSrc);
    },
  },
};
</script>
