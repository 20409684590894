<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import {
  polls,
  presentation as presentationStoreNamespace,
} from "@/store/modules/store.namespaces";
import { RUN_POLL } from "@/store/modules/polls/action-types";
import { presentation } from "@/constants/polls/polls-module-types";
import { CURRENT_PRESENTATION_SLIDE } from "@/store/modules/presentation/getter-types";

export default {
  computed: {
    ...mapGetters(presentationStoreNamespace, {
      currentPresentationSlide: CURRENT_PRESENTATION_SLIDE,
    }),
    ...mapState(`${polls}/${presentation}`, ["activePoll", "chain", "chainScore"]),
    hasPoll() {
      return this.activePoll && this.activePoll.id;
    },
    hasPollChain() {
      return !!this.chain;
    },
    hasCurrentSlide() {
      return !!this.currentPresentationSlide;
    },
    isScreen() {
      return this.$route.meta.isScreen;
    },
  },
  methods: {
    ...mapActions(polls, {
      runQuizAction: RUN_POLL,
    }),
    async runQuiz(slide) {
      if (this.isNeedUpdatePollInfo(slide)) {
        await this.runQuizAction({
          type: presentation,
          isScreen: this.isScreen,
          payload: {
            obj: {
              id: slide.test_id,
              title: "",
              timer: slide.timer || "0:00:00",
              time_started: slide.time_started,
            },
          },
        });
      }
    },
    isTestSlide(slide) {
      return slide.type === SLIDE_TYPE.test;
    },
    isNeedUpdatePollInfo(slide) {
      return (
        !this.hasPoll ||
        !this.hasPollChain ||
        (Number(this.chain.id) !== slide.test_id &&
          this.hasCurrentSlide &&
          !this.currentPresentationSlide.isTestAnswered &&
          this.currentPresentationSlide.id === slide.id)
      );
    },
  },
};
</script>
