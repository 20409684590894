<template>
  <div class="container">
    <lazy-img
      v-if="previewUrlCached"
      class="container-image container-image--cover"
      :src="previewUrlCached"
    />
    <ws-icon v-else class="container__icon" :class="iconClasses">video</ws-icon>
  </div>
</template>

<script>
import YoutubeParser from "@/Utils/VideoServicesUrlSevice";
import { makeUrl } from "@/utils";
import LazyImg from "@/components/base/LazyImg";
import SlideMixin from "./SlideMixin";

export default {
  name: "VideoSlide",
  components: {
    LazyImg,
  },
  mixins: [SlideMixin],
  data() {
    return {
      previewUrlCached: "",
    };
  },
  created() {
    this.getPreviewUrl();
  },
  methods: {
    async getPreviewUrl() {
      if (this.slide.video_slide_preview) {
        return makeUrl(this.slide.video_slide_preview);
      }
      this.previewUrlCached =
        YoutubeParser.getYoutubeImgPreviewUrl(this.slide.video_url) ??
        (await YoutubeParser.getVimeoImgPreviewUrl(this.slide.video_url));
      return this.previewUrlCached;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 60px;
    color: #adc7db;
    transform: translate(-50%, -50%);

    &--size-s {
      font-size: 24px;
    }
  }
}
</style>
