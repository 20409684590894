<template>
  <page
    v-fullscreen="'presentation'"
    class="presentation-page"
    :class="{
      'presentation-page--fullscreen': presentationInFullscreenMode && !$fullscreen.isEnabled,
    }"
    :is-loading="isLoading"
    @click.native="fullscreenOnMounted ? enterToFullscreen : null"
  >
    <template slot="loading">
      <page-message>{{ $t("systemTest.download") }}</page-message>
    </template>
    <template v-if="currentPresentationInfo.id && offlineMode" slot="header">
      <div class="presentation-page__back-btn">
        <back-button @go-back="back" />
      </div>
    </template>
    <template v-if="availablePresentation" slot="default">
      <div v-if="!currentPresentationInfo.id && !offlineMode" class="presentation-page__message">
        {{ $t("Room.noActivePresentations") }}
      </div>
      <presentation-slider
        v-if="currentPresentationInfo.id"
        :permissions="permissions"
        @slide-right="runNextSlide(1)"
        @slide-left="runNextSlide(-1)"
      />
      <presentation-list v-if="!currentPresentationInfo.id && offlineMode" />
    </template>
  </page>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import { isYoutubeUrl, isVimeoUrl } from "@/components/common/MediaPlayer/MediaPlayerUtil";
import { AVAILABLE_ROOM_MODULES, NAVIGATION_ELEMENTS } from "@/store/modules/common/getter-types";
import { room, presentation, broadcast } from "@/store/modules/store.namespaces";
import { CURRENT_PRESENTATION } from "@/store/modules/presentation/getter-types";
import { CHANGE_SLIDE_BY_KEY, RUN_NEXT_SLIDE } from "@/store/modules/presentation/action-types";

import PresentationList from "@/components/common/presentation/PresentationList";
import BackButton from "@/components/common/elements/BackButton";
import withGetPresentationInfo from "@/components/common/presentation/mixins/withGetPresentationInfo.mixin";
import PresentationSlider from "./components/presentation-slider/PresentationSlider.vue";

export default {
  name: "Presentation",
  components: {
    PresentationSlider,
    PresentationList,
    BackButton,
  },
  mixins: [withGetPresentationInfo],
  data() {
    return {
      fullscreenOnMounted: true,
    };
  },
  computed: {
    ...mapGetters(room, {
      navigationElements: NAVIGATION_ELEMENTS,
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
    }),
    ...mapState(presentation, ["presentations", "currentPresentationInfo"]),
    ...mapState(room, {
      room: "roomNumber",
      offlineMode: state => state.roomInfo.Online_room === 0,
    }),
    ...mapState(broadcast, {
      permissions: "permissions",
    }),
    isLoading() {
      return false;
    },
    navigationItems() {
      return this.navigationElements.filter(item => !item.mq || item.mq.includes(this.$mq));
    },
    availablePresentation() {
      return this.availableRoomModules.details || !this.navigationItems.length;
    },
    isNeedEnterToFullscreen() {
      return this.availablePresentation && this.$isMobileLandscape;
    },
    currentSlideIndex() {
      return this.currentPresentationInfo.slideIndex;
    },
    currentSlideIsYouTube() {
      const currentSlide = this.currentPresentation.slides[
        this.currentPresentationInfo.userSelectedSlideIndex || this.currentSlideIndex
      ];
      return currentSlide.type === SLIDE_TYPE.video && isYoutubeUrl(currentSlide.video_url);
    },
    currentSlideIsVimeo() {
      const currentSlide = this.currentPresentation.slides[
        this.currentPresentationInfo.userSelectedSlideIndex || this.currentSlideIndex
      ];
      return currentSlide.type === SLIDE_TYPE.video && isVimeoUrl(currentSlide.video_url);
    },
    presentationInFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "presentation";
    },
  },
  watch: {
    isNeedEnterToFullscreen(value) {
      if (this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast") return;
      if (this.presentationInFullscreenMode && value) return;
      this.toggleFullscreen(value);
    },
    "permissions.presentationControl": function(newVal) {
      if (newVal) {
        document.addEventListener("keydown", this.changeSlideByKey);
      } else {
        document.removeEventListener("keydown", this.changeSlideByKey);
      }
    },
    // тут проблема с переключением слайдов
    "currentPresentationInfo.userSelectedSlideIndex": function(value) {
      if (this.offlineMode && value !== +this.$route.query.slide) {
        // TODO: Костыль, завтра поправить.
        window.history.pushState(
          "presentation",
          "presentation",
          `?slide=${value}&presentation=${this.$route.query?.presentation}`,
        );
        this.$route.query.slide = value;
        // this.$router.push({ query: { ...this.$route.query, slide: value } });
      }
    },
  },
  created() {
    if (!this.room) {
      return;
    }
    this.getData();

    if (!this.offlineMode && this.$route.query?.presentation) {
      this.$router.replace({ query: {} });
    }

    if (this.offlineMode && this.$route.query?.presentation && this.$route.query?.slide) {
      this.setPresentationInfo({
        presentationId: +this.$route.query.presentation,
        slideIndex: +this.$route.query.slide,
        userSelectedSlideIndex: +this.$route.query.slide,
      });
    }
  },
  mounted() {
    this.handleEnterToFullscreen();
  },
  methods: {
    ...mapActions(presentation, {
      changeSlideByKey: CHANGE_SLIDE_BY_KEY,
      runNextSlide: RUN_NEXT_SLIDE,
    }),
    async toggleFullscreen(value) {
      if (value) {
        this.$fullscreen.enter("presentation");
      } else {
        this.$fullscreen.exit("presentation");
      }
    },
    enterToFullscreen() {
      if (this.isNeedEnterToFullscreen && !this.presentationInFullscreenMode) {
        this.toggleFullscreen(true);
      }
    },
    handleEnterToFullscreen() {
      if (this.isNeedEnterToFullscreen) {
        // trigger native click event because https://fullscreen.spec.whatwg.org/#allowed-to-request-fullscreen
        this.$el.click();
      }
      this.$nextTick(() => {
        this.fullscreenOnMounted = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.presentation-page {
  &.Page {
    position: initial;
    max-width: none;
    max-height: 100%;
  }

  &__back-btn {
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 2;
    background-color: #fff;
    border-radius: 5px;
  }

  &__message {
    flex: 1 1 100%;
    align-self: center;
    text-align: center;
  }

  ::v-deep .Page__content {
    max-height: 100%;
  }

  &--fullscreen.Page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}
</style>
