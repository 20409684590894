<template>
  <button
    class="presentation-button"
    v-bind:class="[icon, titleMod, radiusMod]"
    v-bind:style="{
      borderColor: active ? activeColor || color : color,
      backgroundColor: active ? activeColor || color : '#fff',
      color: active ? '#fff' : color,
      marginRight: mr || '',
      marginLeft: ml || '',
    }"
  >
    <div
      v-if="title"
      class="presentation-button__title"
      v-bind:style="{
        color: active ? '#fff' : color,
      }"
    >
      {{ title }}
    </div>
  </button>
</template>

<script>
export default {
  props: [
    "icon",
    "title",
    "color",
    "active",
    "activeColor",
    "mr",
    "ml",
    "radMod",
  ],
  computed: {
    titleMod() {
      return this.$props.title && "presentation-button--title";
    },
    radiusMod() {
      return (
        this.$props.radMod && `presentation-button--${this.$props.radMod}-rad`
      );
    },
  },
};
</script>

<style scoped lang="less">
.presentation-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 70px;
  height: 58px;
  padding: 2px;
  cursor: pointer;
  user-select: none;
  border: solid 1px var(--base-color);
  border-radius: 8px;
  transition: all ease 0.3s;

  &::before {
    font-size: 21px;
  }

  &--title::before {
    font-size: 20px;
  }
}

.presentation-button__title {
  width: 100%;
  height: 15px;
  font-family: ProximaNova, "Helvetica", sans-serif;
  font-size: 11px;
  text-align: center;
  transition: all ease 0.3s;
}
</style>
