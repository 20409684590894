<template>
  <div>
    <loader v-if="!videoSrc" class="loader" />
    <div
      v-show="videoSrc"
      ref="container"
      class="presentation-video-container"
      @click="onErrorClick($event)"
    >
      <video ref="videoPlayer" class="video-js presentation-video" playsinline></video>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { setPlayEvents } from "@/utils";
import Loader from "@/components/common/elements/Loader";
import videojsMixin from "@/mixins/players/videojs.mixin";
import { auth, presentation, room } from "@/store/modules/store.namespaces";
import { CURRENT_PRESENTATION } from "@/store/modules/presentation/getter-types";
import random from "lodash/random";

let oldSrc = null;
let oldPlayTime = null;
export default {
  components: {
    Loader,
  },
  mixins: [videojsMixin],
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
    room: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      player: null,
      options: {
        controls: true,
        autoplay: this.$route.meta.allowEventRecord || false,
        preload: "auto",
        width: 320,
        height: 240,
        sources: [{ src: this.videoSrc, type: "video/mp4" }],
      },
      safeUser: null,
      safePresentation: null,
      pingTimeout: null,
    };
  },
  computed: {
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
    }),
    ...mapState(auth, ["user"]),
    ...mapState(room, ["interfaceLanguage"]),
  },
  watch: {
    videoSrc(val) {
      if (val) {
        oldSrc = val;
        this.player.src({ src: val, type: "video/mp4" });
      }
    },
    "currentPresentation.id": function(val) {
      if (val) {
        this.safePresentation.id = val;
      }
    },
    interfaceLanguage() {
      this.changeErrorMessage();
    },
  },
  async mounted() {
    this.safeUser = { ...this.user };
    this.safePresentation = { ...this.currentPresentation };
    await this.getVideoJs();
    this.setPlayer();
    await this.sendLogs(this.$loggers.$video.Events.Init);
    this.runPing();
  },
  async beforeDestroy() {
    /* eslint-disable no-empty */
    if (this.player) {
      await this.sendLogs(this.$loggers.$video.Events.Close);
      try {
        oldPlayTime = this.player.currentTime();
      } catch (ignore) {}
      oldSrc = this.videoSrc;
      this.player.dispose();
    }
  },
  methods: {
    onErrorClick(e) {
      if (e.target.closest(`.vjs-error`)) {
        this.player.reset();
      }
    },
    setPlaceholder() {
      const iconPlaceholder = document.querySelector(".vjs-icon-placeholder");
      if (iconPlaceholder) {
        iconPlaceholder.dataset.content = this.$t("common.videoText");
      }
    },
    changeErrorMessage() {
      const content = this.$el.querySelector(".vjs-modal-dialog-content");
      if (content) {
        content.innerHTML = this.$t("Presentation.videoUnavailable");
      }
    },
    setPlayer() {
      const self = this;

      this.player = this.videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
        let initdone = false;
        if (!self.$isMobile) {
          this.on("loadedmetadata", function() {
            if (oldSrc === self.videoSrc && oldPlayTime) {
              this.currentTime(oldPlayTime);
              oldPlayTime = null;
            }
            self.setPlaceholder(self);
          });
        } else {
          this.on("canplaythrough", function() {
            if (!initdone) {
              initdone = true;
              if (oldSrc === self.videoSrc && oldPlayTime) {
                this.currentTime(oldPlayTime);
                oldPlayTime = null;
                oldSrc = null;
              }
            }
          });
        }
      });

      this.player.userActive(false);
      setPlayEvents();
      this.player.on("play", () => {
        this.sendLogs(this.$loggers.$video.Events.Play);
      });
      this.player.on("pause", () => {
        this.sendLogs(this.$loggers.$video.Events.Pause);
      });
      this.player.on("seeked", () => {
        this.sendLogs(this.$loggers.$video.Events.TimeUpdate);
      });
      this.player.on("ended", () => {
        this.sendLogs(this.$loggers.$video.Events.Close);
      });
      this.player.on("error", () => {
        this.changeErrorMessage();
      });
    },
    async sendLogs(event) {
      await this.$loggers.$video.sendLog(
        this.$loggers.$video.getPayload({
          user: this.safeUser,
          roomNumber: this.room,
          presentationId: this.safePresentation.id,
          event,
        }),
      );
    },
    runPing() {
      const min = 5;
      const max = 15;
      const timeout = random(min, max) * 60 * 1000;
      this.pingTimeout = setTimeout(() => {
        this.sendLogs(this.$loggers.$video.Events.Ping);
        this.runPing();
      }, timeout);
    },
  },
};
</script>

<style lang="scss">
.presentation-video,
.presentation-video-container {
  height: 100%;
  width: 100%;
  flex: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.presentation-video .vjs-modal-dialog-content {
  color: $white;
}
</style>
