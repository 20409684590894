<template>
  <svg
    class="emotion"
    :class="iconClass"
    viewBox="0 0 40 41"
    xmlns="http://www.w3.org/2000/svg"
    @click="like"
  >
    <g>
      <path
        stroke="#F28B00"
        d="M14.0625 17.25H10.9375C10.3906 17.25 10 17.6797 10 18.1875V27.5625C10 28.1094 10.3906 28.5 10.9375 28.5H14.0625C14.5703 28.5 15 28.1094 15 27.5625V18.1875C15 17.6797 14.5703 17.25 14.0625 17.25ZM12.5 26.9375C11.9531 26.9375 11.5625 26.5469 11.5625 26C11.5625 25.4922 11.9531 25.0625 12.5 25.0625C13.0078 25.0625 13.4375 25.4922 13.4375 26C13.4375 26.5469 13.0078 26.9375 12.5 26.9375ZM25 11.7031C25 8.8125 23.125 8.5 22.1875 8.5C21.3672 8.5 21.0156 10.0625 20.8594 10.7656C20.625 11.625 20.4297 12.4844 19.8438 13.0703C18.5938 14.3594 17.9297 15.9609 16.3672 17.4844C16.2891 17.6016 16.25 17.7188 16.25 17.8359V26.1953C16.25 26.4297 16.4453 26.625 16.6797 26.6641C17.3047 26.6641 18.125 27.0156 18.75 27.2891C20 27.8359 21.5234 28.5 23.3984 28.5H23.5156C25.1953 28.5 27.1875 28.5 27.9688 27.3672C28.3203 26.8984 28.3984 26.3125 28.2031 25.6094C28.8672 24.9453 29.1797 23.6953 28.8672 22.6797C29.5312 21.7812 29.6094 20.4922 29.2188 19.5938C29.6875 19.125 30 18.3828 29.9609 17.6797C29.9609 16.4688 28.9453 15.375 27.6562 15.375H23.6719C23.9844 14.2812 25 13.3438 25 11.7031Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0.5"
        width="39.9642"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PulseIconItem",
  props: ["icon", "status"],
  computed: {
    iconClass() {
      return {
        "emotion--active": this.status[this.icon],
        [`emotion--${this.icon}`]: this.icon,
      };
    },
  },
  methods: {
    like() {
      this.$emit("like", this.icon);
    },
  },
};
</script>

<style lang="less" scoped>
.emotion {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 5px;
  vertical-align: middle;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  fill: white;

  &--active {
    fill: #f28b00;
  }

  &--unclear {
    transform: rotate(270deg);
  }

  &--dislike {
    transform: rotate(180deg);
  }
}
</style>
