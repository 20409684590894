<script>
import WsIcon from "@/components/base/WsIcon";

const DEFAULT_SLIDE_IMG_SIZE = "/resize_1920_1080_75";

export default {
  components: {
    WsIcon,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isRoomRoute: {
      type: Boolean,
      default: false,
    },
    imgResizerParams: {
      type: String,
      default: DEFAULT_SLIDE_IMG_SIZE,
    },
    // Опции для ресайзера изображений через сервис cloudflare
    cloudflareOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    iconClasses() {
      return {
        "container__icon--size-s": this.isRoomRoute,
      };
    },
  },
};
</script>
