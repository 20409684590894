<template>
  <presentation-player
    v-if="hasPresentationVideo && !isPresentationVideoYt"
    ref="player"
    :video-src="presentationVideoSrc"
    :room="room"
  />
  <presentation-player
    v-else-if="slide && isMp4Video"
    ref="player"
    :video-src="videoSrc"
    :room="room"
  />
  <media-player
    v-else-if="(slide || hasPresentationVideo) && (isYoutubeVideo || isVimeoVideo)"
    ref="player"
    class="presentation-youtube"
    :url="urlSrc"
  />
</template>

<script>
import { mapState } from "vuex";
import MediaPlayer from "@/components/common/MediaPlayer/MediaPlayer";
import YoutubeParser from "@/Utils/VideoServicesUrlSevice";
import { broadcast } from "@/store/modules/store.namespaces";
import PresentationPlayer from "./components/PresentationPlayer";

export default {
  name: "PresentationScreenVideoSlide",
  components: {
    PresentationPlayer,
    MediaPlayer,
  },
  props: {
    hasPresentationVideo: {
      type: Boolean,
      default: false,
    },
    presentationVideoSrc: {
      type: String,
      default: "",
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
    room: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(broadcast, ["onAir", "webRTC"]),
    videoSrc() {
      return this.slide?.video_url ?? "";
    },
    isYoutubeVideo() {
      return this.isPresentationVideoYt || this.videoSrc.includes("youtu");
    },
    isVimeoVideo() {
      return this.isPresentationVideoVimeo || this.videoSrc.includes("vimeo");
    },
    isPresentationVideoYt() {
      return this.presentationVideoSrc.includes("youtu");
    },
    isPresentationVideoVimeo() {
      return this.presentationVideoSrc.includes("vimeo");
    },
    isMp4Video() {
      return this.videoSrc.includes(".mp4");
    },
    youtubeSrc() {
      const src = this.isYoutubeVideo
        ? YoutubeParser.getYoutubeEmbedUrl(
            this.hasPresentationVideo
              ? this.presentationVideoSrc
              : this.videoSrc,
          )
        : "";
      return src;
    },
    vimeoSrc() {
      const src = this.isVimeoVideo
        ? YoutubeParser.getVimeoEmbedUrl(
          this.hasPresentationVideo
            ? this.presentationVideoSrc
            : this.videoSrc,
        )
        : "";
      console.log(src)
      return src;
    },
    urlSrc(){
      const yt = this.youtubeSrc;
      if(yt !== "")
        return yt
      return this.vimeoSrc;
    },
  },
  watch: {
    onAir(value) {
      if (value) {
        this.setPlaysInline();
      }
    },
  },
  updated() {
    this.setPlaysInline();
  },
  methods: {
    setPlaysInline() {
      if (this.onAir && this.webRTC && this.$refs.player) {
        this.$refs.player.$el.querySelectorAll("video").forEach(el => {
          el.setAttribute("playsinline", "playsinline");
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.presentation-youtube {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: auto;
  width: 100%;
  height: 100%;
  margin: auto;

  @media (max-width: 640px) {
    position: static;
  }
}
</style>
