<template>
  <component :is="componentType" v-bind="componentProps"></component>
</template>

<script>
import { mapState } from "vuex";
import UserRoles from "@/constants/user/roles-const";
import { auth } from "@/store/modules/store.namespaces";
import PollRunning from "@/components/common/polls/components/PollRunning";
import { POLL, POLL_RUNNING } from "@/constants/polls/poll-interactive-types";
import Poll from "./components/Poll";
import BasePollContainer from "./components/BasePollContainer";

export default {
  name: "PollContainer",
  components: {
    Poll,
    PollRunning,
  },
  extends: BasePollContainer,
  props: {
    // Из слайда ли вопрос
    isFromSlide: {
      type: Boolean,
      default: false,
    },
    // Объект слайда
    slide: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(auth, ["user"]),
    pollChain() {
      return this.$store.state.polls[this.moduleType].chain;
    },
    /**
     * Текущий вопрос
     */
    poll() {
      return this.$store.state.polls[this.moduleType].activePoll;
    },
    /**
     * Параметр для отображения загрузки (прелоадер)
     * пока не придет ответ она помечается как в процессе, после прихода как оконченная.
     */
    sendingStatus() {
      return this.$store.state.polls[this.moduleType].sendingStatus;
    },
    /**
     * Принадлежит ли пользователь группе "монитор"
     */
    userIsMonitor() {
      return this.user.role === UserRoles.Screen;
    },
    /**
     * Тип компонента, опрос или страница для монитора
     */
    componentType() {
      return this.userIsMonitor ? POLL_RUNNING : POLL;
    },
    /**
     * Определяет необходимые входные параметры для компонента опроса/страницы монитора
     *
     * @returns {object} - данные, нужные для компонента монитора или опроса
     */
    componentProps() {
      return this.userIsMonitor
        ? {
            poll: this.poll,
            pollChain: this.pollChain,
          }
        : {
            poll: this.poll,
            pollChain: this.pollChain,
            sendingStatus: this.sendingStatus,
            moduleType: this.moduleType,
            slide: this.slide,
            isFromSlide: this.isFromSlide,
          };
    },
  },
};
</script>
