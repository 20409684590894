<template>
  <div class="poll-running" :class="{ 'poll-running--with-menu': pinStatus }">
    <div class="poll-running__data">
      <div class="poll-running__pict">
        <qrcode-vue
          class="poll-running__qrcode"
          :value="userEnterUrl"
          :size="qrCodeSize"
          level="H"
        ></qrcode-vue>
      </div>

      <div class="poll-running__text">
        <div class="poll-running__note">
          {{ $t("PollRunning.scanQrcode") }}
        </div>
        <div class="poll-running__divider">
          {{ $t("PollRunning.divider") }}
        </div>
        <div class="poll-running__note">
          {{ $t("PollRunning.visitWebsite") }}
          <br />
          <strong class="poll-running__strong">
            {{ roomUrlHost }}
          </strong>
        </div>
        <div class="poll-running__note">
          {{ $t("PollRunning.enterTheNumber") }}
          <strong class="poll-running__strong">
            {{ room }}
          </strong>
        </div>
      </div>
    </div>

    <div class="poll-running__info">
      <div class="poll-running__lead">
        {{ $t("PollRunning.pollIsRunning") }}
      </div>
      <div class="poll-running__title">
        {{ poll.testName }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import QrcodeVue from "qrcode.vue";
import { room } from "@/store/modules/store.namespaces";

export default {
  name: "PollRunning",
  components: {
    QrcodeVue,
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    /**
     * Информация о тесте/опросе для отправки на монитор.
     */
    pollChain: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qrCodeSize: 300,
    };
  },
  computed: {
    ...mapState(["pinStatus"]),
    ...mapState(room, {
      room: "roomNumber",
      companyDomain: room => room.roomInfo.company_domain_id,
    }),
    /**
     * URL для QR-кода
     *
     * @returns {string} - ссылка на комнату, которая отображается в QR коде
     */
    userEnterUrl() {
      return `https://${this.companyDomain || window.location.origin}/guest/${this.room}`;
    },
    /**
     * Ссылка на комнату
     *
     * @returns {string} - ссылка на комнату, которая отображается на фронте
     */
    roomUrlHost() {
      return `${this.companyDomain || window.location.hostname}`;
    },
  },
};
</script>

<style lang="less" scoped>
.poll-running {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  font-size: 1vh;
  color: #000000;
  background-color: var(--base-color);

  &--with-menu {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 95;
    margin: 0;
  }

  &__data {
    flex: 0 0 75em;
    padding: 5em 11em 0;
    text-align: center;
    background-color: #ffffff;
  }

  &__info {
    flex: 1 1 auto;
    padding: 5em 7.5em 5em 12em;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__title {
    margin: 0;
    font-size: 5.5em;
    font-weight: 600;
    line-height: 1.24;
    word-break: break-word;
  }

  &__lead {
    margin-bottom: 1em;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1.2;
    color: #303030;
  }

  &__pict {
    margin: 0 1em 5em;
  }

  &__qrcode {
    position: relative;
    padding-top: 100%;

    ::v-deep canvas {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__note {
    margin-top: 1em;
    font-size: 2.8em;
    line-height: 1.1em;
  }

  &__strong {
    font-size: 150%;
    font-weight: 600;
  }

  &__divider {
    position: relative;
    margin: 0.9em 0;
    font-size: 3em;
    line-height: 1.3;
    color: #303030;
    text-align: center;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      display: block;
      width: 43%;
      content: "";
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}
</style>
