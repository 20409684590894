<template>
  <div
    class="presentation-screen"
    :class="{
      'presentation-screen--test': slide && slide.type === 'test' && slide.isTestAnswered === false,
      'presentation-screen--control': permissions.presentationControl,
    }"
  >
    <div class="presentation-screen__inner" @click="showControl">
      <presentation-screen-video-slide
        v-if="isVideoSlide"
        :room="room"
        :has-presentation-video="videoStatus"
        :presentation-video-src="videoSrc"
        :slide="videoSlide"
      />
      <presentation-screen-test-slide v-else-if="showTestSlide" :slide="slide" />
      <template v-else>
        <presentation-slide-pinch-zoom
          v-if="allowSlideZoom"
          :image-src="slideSrc"
          @tap="showControl"
        />
        <lazy-img
          v-else
          v-show="slideSrc"
          ref="image"
          :key="slide.id"
          alt="slide"
          class="presentation-image"
          :src="slideSrc"
          :enableCloudflare="false && !isResultsSlide"
          :cloudflare-options="{ quality: 70 }"
        />
      </template>
    </div>
    <presentation-skin v-show="isShowControl" />
    <ws-button
      v-if="permissions.presentationControl"
      class="presentation-screen__btn presentation-screen__btn-left"
      fab
      color="primary"
      @click="slideLeft"
    >
      <ws-icon>
        angle-left
      </ws-icon>
    </ws-button>
    <ws-button
      v-if="permissions.presentationControl"
      class="presentation-screen__btn presentation-screen__btn-right"
      fab
      color="primary"
      @click="slideRight"
    >
      <ws-icon>
        angle-right
      </ws-icon>
    </ws-button>

    <!-- TODO: Решить проблему с перезагрузкой страницы, google блокирует повторный запуск -->
    <div class="music_container">
      <audio
        v-if="slide && slide.audio_url"
        ref="bgMusic"
        autoplay
        loop
        :src="slide.audio_url"
        @play="setVolume(0.3)"
        @suspend="showMusicButton"
      ></audio>

      <ws-button
        v-if="isMusicButtonShow"
        lg
        color="primary"
        class="music_button"
        @click="toggleMusic"
      >
        <ws-icon xl light>fas fa-play</ws-icon>
        Возобновить музыку на заднем фоне
      </ws-button>
    </div>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
// eslint-disable-next-line max-len
import presentationTestSlideMixin from "@/components/event/room/Presentation/components/mixins/presentationTestSlideMixin";
import presentationScreenSlideHelper from "@/components/common/presentation/mixins/presentationScreenSlideHelper.mixin";
import PresentationScreenVideoSlide from "@/components/common/presentation/PresentationScreenVideoSlide";
import PresentationScreenTestSlide from "@/components/common/presentation/PresentationScreenTestSlide";
import LazyImg from "@/components/base/LazyImg";
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import PresentationSkin from "./PresentationSkin";
import PresentationSlidePinchZoom from "./PresentationSlidePinchZoom";

export default {
  components: {
    PresentationSkin,
    PresentationScreenVideoSlide,
    PresentationScreenTestSlide,
    PresentationSlidePinchZoom,
    WsButton,
    WsIcon,
    LazyImg,
  },
  mixins: [presentationTestSlideMixin, presentationScreenSlideHelper],
  props: {
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowControl: false,
      isMusicButtonShow: false,
    };
  },
  computed: {
    allowSlideZoom() {
      return (
        this.$isTouch && this.$fullscreen.isFullscreen && this.$fullscreen.group === "presentation"
      );
    },
    // Является ли слайд результирующим.
    isResultsSlide() {
      return this.slide.type === SLIDE_TYPE.results;
    },
  },
  methods: {
    showControl() {
      this.isShowControl = !this.isShowControl;
    },
    slideLeft() {
      this.$emit("slide-left");
    },
    slideRight() {
      this.$emit("slide-right");
    },
    setVolume(volume) {
      const { bgMusic } = this.$refs;
      // временное решение
      this.isMusicButtonShow = false;
      bgMusic.volume = volume;
    },
    toggleMusic() {
      const { bgMusic } = this.$refs;
      // временное решение
      this.isMusicButtonShow = false;
      bgMusic.paused ? bgMusic.play() : bgMusic.pause();
    },
    showMusicButton() {
      const { bgMusic } = this.$refs;
      if (bgMusic.paused) {
        this.isMusicButtonShow = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.presentation-image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: auto;
  object-fit: contain;
  object-position: center;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
}

.presentation-screen {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  padding: 8px;
  overflow: hidden;
  touch-action: none;
  //background-color: #fff;

  @media (min-width: 640px) {
    width: auto;
    margin: 0;
  }

  &--test {
    justify-content: normal;
  }

  &--control {
    padding: 0;

    @media (min-width: 768px) {
      padding: 8px 60px;
    }
  }

  &--user-speaker {
    padding: 8px 60px;
  }

  &__inner {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    touch-action: pan-x pan-y;
  }

  &__btn {
    position: absolute;
    top: 50%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0;
    transform: translateY(-50%);

    @media (min-width: 768px) {
      height: calc(var(--button-height) * 1.4);
      background: var(--ws-btn-color-primary-bg);
      border-radius: 50%;
    }
  }

  &__btn-left {
    left: 0;

    @media (min-width: 768px) {
      left: 8px;
    }
  }

  &__btn-right {
    right: 0;

    @media (min-width: 768px) {
      right: 8px;
    }
  }

  .music_container .music_button {
    margin: 15px 0;
  }
}
</style>
