<script>
import { mapActions, mapMutations } from "vuex";
import { GET_PRESENTATIONS } from "@/store/modules/presentation/action-types";
import { SET_PRESENTATION_INFO } from "@/store/modules/presentation/mutation-types";
import { GET_PRESENTATION_LIKES, GET_SLIDE_LIKES } from "@/store/modules/user/action-types";
import { presentation, user } from "@/store/modules/store.namespaces";

export default {
  name: "withGetPresentationInfo",
  methods: {
    ...mapActions(presentation, {
      getPresentations: GET_PRESENTATIONS,
    }),
    ...mapMutations(presentation, {
      setPresentationInfo: SET_PRESENTATION_INFO,
    }),
    ...mapActions(user, {
      getSlidelikes: GET_SLIDE_LIKES,
      getPresentationLikes: GET_PRESENTATION_LIKES,
    }),
    getData() {
      this.getSlidelikes();
      this.getPresentationLikes();
      this.getPresentations();
    },
    back() {
      this.$router.replace({ query: {} });
      this.setPresentationInfo({
        presentationId: false,
        slideIndex: 1,
      });
    },
  },
};
</script>
