<template>
  <poll-score-container v-if="showScore" :is-screen="isScreen" module-type="presentation">
  </poll-score-container>

  <test-slide-result v-else-if="slide.type === 'results'" :slide="slide" :isActive="true" />

  <poll-container
    v-else-if="!slide.isTestAnswered && !showScore"
    :is-from-slide="true"
    :slide="slide"
    module-type="presentation"
  >
  </poll-container>

  <poll-thanks-for-answer v-else-if="slide.isTestAnswered" :show-label="false">
  </poll-thanks-for-answer>
</template>

<script>
import PollContainer from "@/components/common/polls/PollContainer";
import PollScoreContainer from "@/components/common/polls/PollScoreContainer";
import PollThanksForAnswer from "@/components/common/polls/components/PollThanksForAnswer";
import TestSlideResult from "@/components/common/polls/components/common/TestSlideResult.vue";

export default {
  name: "PresentationScreenTestSlide",
  components: {
    PollContainer,
    PollScoreContainer,
    TestSlideResult,
    PollThanksForAnswer,
  },
  props: {
    showScore: {
      type: Boolean,
      default: false,
    },
    isScreen: {
      type: Boolean,
      default: false,
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
