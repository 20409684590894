<template>
  <div
    ref="slides"
    class="presentation-slides"
    :class="{ 'full-screen': onfullscreen }"
    @scroll="emitScrollToParent"
    @mousedown.prevent="startScroll"
    @mouseup.prevent="stopScroll"
    @mousemove.prevent="moveSlider"
    @mouseout="stopScrollMouseOut"
  >
    <div
      v-for="slide in slides"
      :key="slide.id"
      class="presentation-slides__slide"
      :class="{
        'presentation-slides__slide--active': slide === currentSlide,
        'presentation-slides__slide--hidden': slide.type === 'timer',
      }"
      @click="onSlideClick(slide)"
    >
      <component
        :is="componentSlidesMap[`${slide.type}-slide`]"
        :slide="slide"
        :imgResizerParams="`/preview_146_100_75`"
        :cloudflare-options="{ width: 100, quality: 70 }"
        is-room-route
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import TestSlideResult from "@/components/common/polls/components/common/TestSlideResult.vue";
import {
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDES,
} from "@/store/modules/presentation/getter-types";
import { presentation, room } from "@/store/modules/store.namespaces";
import { SET_USER_SLIDE_INDEX } from "@/store/modules/presentation/mutation-types";
import slidesHorizontalScrollMixin from "@/components/common/slides/mixins/slidesHorizontalScrollMixin";
import TimerSlide from "@/components/common/presentation/components/TimerSlide";
import ImageSlide from "./ImageSlide";
import VideoSlide from "./VideoSlide";
import QuizSlide from "./QuizSlide";

/**
 * Slides navigation panel
 *
 * @props
 *  videoStatus {?Boolean} //TODO check type and purpose
 *  onfullscreen {Boolean}
 *    set slide on full screen
 *  videoSlide {?}  //TODO delete if unused
 * @emit
 *  presentationSlidesScrolling
 *    @param null
 *
 */
export default {
  components: {
    QuizSlide,
    VideoSlide,
    ImageSlide,
    TestSlideResult,
    TimerSlide,
  },
  mixins: [slidesHorizontalScrollMixin],
  props: ["videoStatus", "onfullscreen", "videoSlide", "presentationControl"],

  computed: {
    ...mapState(room, ["roomInfo"]),
    ...mapState(presentation, ["currentPresentationInfo"]),
    ...mapGetters(presentation, {
      currentPresentationSlides: CURRENT_PRESENTATION_SLIDES,
      currentSlide: CURRENT_PRESENTATION_SLIDE,
    }),
    slides() {
      const presSlides = this.currentPresentationSlides;
      const { slideIndex } = this.currentPresentationInfo;
      if (this.presentationControl) {
        return presSlides;
      }
      if (!this.videoStatus && this.online) {
        return presSlides.slice(0, slideIndex);
      }

      return presSlides;
    },
    online() {
      return !!this.roomInfo.Online_room;
    },
    /**
     * Because your can't use 'video' as bind component name
     *
     * @see [Vue warn]: Do not use built-in or reserved HTML elements as component id
     */
    componentSlidesMap() {
      return {
        "test-slide": QuizSlide,
        "video-slide": VideoSlide,
        "extimg-slide": ImageSlide,
        "img-slide": ImageSlide,
        "results-slide": TestSlideResult,
        "timer-slide": TimerSlide,
      };
    },
  },

  mounted() {
    // TODO: scrollIntoView is async, but using scrollSlideIntoView by this.$nextTick does not work
    setTimeout(this.scrollSlideIntoView, 500);
  },

  updated() {
    this.scrollSlideIntoView();
  },

  methods: {
    ...mapMutations(presentation, {
      setUserSlideIndex: SET_USER_SLIDE_INDEX,
    }),
    scrollSlideIntoView() {
      const slideIndex =
        this.currentPresentationSlides.findIndex(s => s.id === this.currentSlide.id) - 1;
      const { slides } = this.$refs;
      if (slides && slides.children[0] && slides.children[slideIndex]) {
        this.$nextTick(() => {
          const slideEl = slides.children[slideIndex];
          const slideElWidth = slideEl.getBoundingClientRect().width;
          const sliderWidth = slides.getBoundingClientRect().width;
          const middleOfSlider =
            slideEl.offsetLeft + slideElWidth - sliderWidth / 2 + slideElWidth / 2;

          this.$scrollTo(slides, { left: middleOfSlider, duration: 300 });
        });
      }
    },
    emitScrollToParent() {
      this.$emit("presentationSlidesScrolling");
    },
    onSlideClick({ ord }) {
      this.setUserSlideIndex(ord);
    },
    isTimerSlide(slide) {
      return slide.type === "timer";
    },
  },
};
</script>

<style scoped lang="less">
@import url("~@/styles/_mixin");

.presentation-slides {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  padding: 8px;
  overflow: hidden;
  overflow-x: auto;
  cursor: pointer;
  background: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    display: block;
    width: 8px;
    min-width: 8px;
    height: 57px;
    content: "";
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
  }
}

.presentation-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  font-family: %("object-fit: %a; object-position: %a;", cover, center);
}

.presentation-slide__icon {
  font-size: 20px;
}

.presentation-slides__slide {
  position: relative;
  display: flex;
  flex-basis: 75px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 57px;
  margin-right: 6px;
  overflow: hidden;
  cursor: pointer;
  background: no-repeat center center;
  background-size: cover;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  transition: all ease 0.3s;

  &--active {
    border-color: var(--base-color);
    box-shadow: 0 0 0 1px var(--base-color);
  }

  &--hidden {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }

  ::v-deep .container-image--cover {
    object-fit: cover;
  }
}
</style>
