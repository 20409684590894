<template>
  <div
    id="presentation-slider"
    ref="slider"
    class="presentation-slider"
    :class="{
      'presentation-slider--fullscreen': inFullscreenMode,
      'presentation-slider--controls-visible': controlsIsVisible,
    }"
    @click="toggleControlsVisible"
  >
    <presentation-screen
      class="presentation-slider__screen"
      :video-status="hasVideoSrc && !inFullscreenMode"
      :slide-src="slideSrc"
      :video-src="videoSrc"
      :video-slide="videoSlide"
      :slide="currentSlide"
      :permissions="permissions"
      @slide-right="$emit('slide-right')"
      @slide-left="$emit('slide-left')"
    />
    <presentation-slides
      class="presentation-slider__slides"
      :video-status="hasVideoSrc"
      :onfullscreen="inFullscreenMode"
      :presentation-control="permissions.presentationControl"
      @click.native="resetTimeout"
      @presentationSlidesScrolling="resetTimeout"
    />
    <presentation-buttons v-if="!inFullscreenMode" :video-src="videoSrc" />
    <div
      v-if="inFullscreenMode && controlsIsVisible"
      class="presentation-slider__close-button presentation-slider__control icon-close"
      @click="exitFullscreen"
    ></div>
    <div v-if="controlsIsVisible" class="presentation-slider__arrow-controls">
      <div
        v-if="showLeft"
        class="presentation-slider__arrow-left icon-arrow-left-circle presentation-slider__control"
        @click.stop="changeByArrow($event, true)"
      ></div>
      <div
        v-if="showRight"
        class="presentation-slider__arrow-right icon-arrow-right-circle presentation-slider__control"
        @click.stop="changeByArrow($event, false)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import presentationSliderPropsMixin from "@/components/common/presentation/mixins/presentationSliderProps.mixin";
import { presentation, room } from "@/store/modules/store.namespaces";
import { SET_USER_SLIDE_INDEX } from "@/store/modules/presentation/mutation-types";
import {
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDES,
  SELECTED_SLIDE_ORD,
} from "@/store/modules/presentation/getter-types";
// eslint-disable-next-line max-len
import presentationTestSlideMixin from "@/components/event/room/Presentation/components/mixins/presentationTestSlideMixin";
import PresentationButtons from "./presentation-buttons/PresentationButtons.vue";
import PresentationSlides from "./presentation-slides/PresentationSlides.vue";
import PresentationScreen from "./presentation-screen/PresentationScreen.vue";

/* eslint-enable max-len */
const HIDE_CONTROLS_TMT = 2000; // 0 for disable hide

const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

export default {
  name: "PresentationSlider",
  components: {
    PresentationScreen,
    PresentationSlides,
    PresentationButtons,
  },
  mixins: [presentationSliderPropsMixin, presentationTestSlideMixin],
  props: {
    permissions: {
      type: Object,
      default: () => ({
        canPresent: false,
        audio: false,
        video: false,
        presentationControl: false,
      }),
    },
  },
  data() {
    return {
      controlsIsVisible: false,
      hideControlsTmt: null,
      parentEl: null,
    };
  },
  computed: {
    ...mapState(presentation, ["currentPresentationInfo"]),
    ...mapGetters(presentation, {
      currentPresentationSlide: CURRENT_PRESENTATION_SLIDE,
      currentPresentationSlides: CURRENT_PRESENTATION_SLIDES,
      selectedSlideOrd: SELECTED_SLIDE_ORD,
    }),
    ...mapState(room, ["roomInfo"]),
    slides() {
      const presSlides = this.currentPresentationSlides;
      const { slideIndex } = this.currentPresentationInfo;
      if (!this.videoSrc && this.online) {
        return presSlides.slice(0, slideIndex);
      }
      return presSlides;
    },
    online() {
      return !!this.roomInfo.Online_room;
    },
    showRight() {
      return this.slides[this.slides.length - 1] !== this.currentPresentationSlide;
    },
    showLeft() {
      return this.slides[0] !== this.currentPresentationSlide;
    },
    inFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "presentation";
    },
  },
  watch: {
    "$fullscreen.isFullscreen": function(isFullscreen) {
      if (this.$fullscreen.group === "presentation" && isFullscreen) {
        document.addEventListener("keyup", this.keyUpHandler);
        this.toggleControlsVisible();
      }
    },
    "$fullscreen.group": function(group, oldGroup) {
      if (!group && oldGroup === "presentation") {
        this.reset();
      }
    },
  },
  methods: {
    ...mapMutations(presentation, {
      setUserSlideIndex: SET_USER_SLIDE_INDEX,
    }),
    exitFullscreen(e) {
      e.stopPropagation();
      this.$fullscreen.exit("presentation");
    },
    toggleControlsVisible() {
      if (!this.$fullscreen.isFullscreen) return;
      if (this.controlsIsVisible) {
        this.controlsIsVisible = false;
        if (this.hideControlsTmt) {
          clearTimeout(this.hideControlsTmt);
          this.hideControlsTmt = null;
        }
      } else {
        this.controlsIsVisible = true;
        if (!this.hideControlsTmt && HIDE_CONTROLS_TMT) {
          this.hideControlsTmt = setTimeout(() => {
            this.controlsIsVisible = false;
            this.hideControlsTmt = null;
          }, HIDE_CONTROLS_TMT);
        }
      }
    },
    reset() {
      this.controlsIsVisible = false;
      if (this.hideControlsTmt) {
        clearTimeout(this.hideControlsTmt);
        this.hideControlsTmt = null;
      }
      document.removeEventListener("keyup", this.keyUpHandler);
    },
    keyUpHandler(e) {
      if (e.keyCode === LEFT_ARROW_KEY && this.inFullscreenMode) {
        if (this.showLeft) {
          this.changeByArrow(e, true);
        }
      }
      if (e.keyCode === RIGHT_ARROW_KEY && this.inFullscreenMode) {
        if (this.showRight) {
          this.changeByArrow(e);
        }
      }
    },
    changeByArrow(event, backward) {
      event.stopPropagation();
      const ord = backward ? this.selectedSlideOrd - 1 : this.selectedSlideOrd + 1;
      this.setUserSlideIndex(ord);
      this.$nextTick(() => {
        if (this.isTestSlide(this.currentPresentationSlide)) {
          this.runQuiz(this.currentPresentationSlide);
        }
      });
      this.resetTimeout();
    },
    resetTimeout(e) {
      if (e) {
        e.stopPropagation();
      }
      if (this.hideControlsTmt && HIDE_CONTROLS_TMT) {
        clearTimeout(this.hideControlsTmt);
        this.hideControlsTmt = setTimeout(() => {
          this.controlsIsVisible = false;
          this.hideControlsTmt = null;
        }, HIDE_CONTROLS_TMT);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url("~@/styles/_mixin");
.presentation-slider {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  &__control {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 30px;
    pointer-events: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.02);
    }
  }

  &__close-button {
    top: 10px;
    right: -80px;
  }

  &--fullscreen {
    ::v-deep .presentation-image {
      filter: none;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;

      .object-fit(contain);
    }
  }

  &--controls-visible &__control {
    opacity: 1;
    pointer-events: all;
  }

  &--controls-visible &__close-button {
    right: 10px;
  }

  &--fullscreen &__slides {
    position: absolute;
    left: 50%;
    bottom: -80px;
    max-width: 500px;
    opacity: 0;
    pointer-events: none;
    background: transparent;
    transition: all 0.3s;
    transform: translateX(-50%);
  }

  &--controls-visible &__slides {
    opacity: 1;
    pointer-events: all;
    bottom: 20px;
  }

  &--fullscreen &__screen {
    height: 100%;
  }

  &__arrow-controls {
    position: absolute;
    top: calc(49%);
    height: 36px;
    width: 100%;
  }

  &__arrow-left {
    left: -80px;
  }

  &__arrow-right {
    right: -80px;
  }

  &--controls-visible &__arrow-left {
    left: 10px;
  }

  &--controls-visible &__arrow-right {
    right: 10px;
  }
}
</style>
