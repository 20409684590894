var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"presentation-button",class:[_vm.icon, _vm.titleMod, _vm.radiusMod],style:({
    borderColor: _vm.active ? _vm.activeColor || _vm.color : _vm.color,
    backgroundColor: _vm.active ? _vm.activeColor || _vm.color : '#fff',
    color: _vm.active ? '#fff' : _vm.color,
    marginRight: _vm.mr || '',
    marginLeft: _vm.ml || '',
  })},[(_vm.title)?_c('div',{staticClass:"presentation-button__title",style:({
      color: _vm.active ? '#fff' : _vm.color,
    })},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }