<script>
import { mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";

export default {
  props: ["slideSrc", "videoSrc", "videoStatus", "videoSlide", "slide"],
  computed: {
    ...mapState(room, {
      room: "roomNumber",
    }),
    showTestSlide() {
      return this.slide && this.slide.test_id;
    },
    showTestScore() {
      return !!(
        this.showTestSlide &&
        this.activePoll &&
        this.activePoll.show_projector_result &&
        this.chainScore &&
        this.chainScore.id
      );
    },
    isVideoSlide() {
      return this.videoStatus || !!this.videoSlide;
    },
  },
  watch: {
    async slide(newSlide) {
      if (newSlide && newSlide.test_id) {
        await this.runQuiz(newSlide);
      }
    },
  },
  async mounted() {
    if (this.slide && this.slide.test_id) {
      await this.runQuiz(this.slide);
    }
  },
  updated() {
    // lifehack for Ipad 2
    const { image } = this.$refs;
    if (!image) {
      return;
    }

    image.src = image.src;
  },
};
</script>
