<template>
  <div class="quiz-slide container">
    <loader v-if="pending && isActive" />
    <template v-else-if="displayInfo || !isActive">
      <div v-if="isActive">
        <h3 class="quiz-slide__title">{{ quizName }}</h3>
        <div class="quiz-slide__text">{{ answersString }}</div>
      </div>
      <div v-else>
        <ws-icon class="container__icon" :class="iconClasses">ballot </ws-icon>
      </div>
    </template>
  </div>
</template>

<script>
import AdminWebHttpService from "@/services/http/AdminWebHttpService";
import { setInterval, clearInterval } from "timers";

import Loader from "@/components/common/elements/Loader";
import AuthService from "@/services/AuthService";
import config from "@/settings/config";
import axios from "axios";
import SlideMixin from "./SlideMixin";

export default {
  name: "QuizSlide",
  components: {
    Loader,
  },
  mixins: [SlideMixin],
  data() {
    return {
      quizName: "",
      respondentsCount: 0,
      intervalTimerId: null,
      pending: true,
      displayInfo: false,
    };
  },
  computed: {
    answersString() {
      return `${this.$t("Slides.quizSlideAnswers")}:${this.respondentsCount}`;
    },
  },
  watch: {
    "slide.test_id": function(id) {
      this.getQuizInfo(id);
    },
  },
  mounted() {
    if (this.isActive) {
      this.getQuizInfo(this.slide.test_id, false);
      this.intervalTimerId = setInterval(() => {
        this.getQuizInfo(this.slide.test_id);
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalTimerId);
    this.intervalTimerId = null;
  },
  methods: {
    async getQuizInfo(quizId, pending = false) {
      try {
        // const { data } = await AdminWebHttpService.get(`getResults/${quizId}`);

        const { data } = await axios.get(
          `${config.serverUrl}/questionnaire/api/get-all-users-test-result`,
          {
            headers: {
              "x-token": AuthService.getToken(),
            },
            params: {
              test_id: quizId,
            },
          },
        );

        this.respondentsCount = data.user_count;
        this.quizName = data.questions.length
          ? data.questions[0].question || data.questions[0].name
          : "";
        if (!pending) {
          this.pending = pending;
          setTimeout(() => {
            this.displayInfo = true;
          }, 320);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 30px;
  text-align: center;

  &__icon {
    font-size: 60px;
    color: #adc7db;

    &--size-s {
      font-size: 24px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

.quiz-slide {
  &__title {
    position: relative;
    padding-bottom: 25px;
    margin: 15px 15px 25px 15px;
    font-size: 24px;
    font-weight: bold;
    color: #232323;
    text-align: center;
    word-break: break-word;
  }
}
</style>
