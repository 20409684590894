<template>
  <div
    class="container"
    :class="{
      'container--error': error,
    }"
  >
    <lazy-img
      class="container--image"
      alt="Slide"
      :src="previewUrl"
      :cloudflareOptions="cloudflareOptions"
      @error="error = true"
    />
  </div>
</template>

<script>
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import { makeUrl } from "@/utils";
import LazyImg from "@/components/base/LazyImg";
import SlideMixin from "./SlideMixin";

export default {
  name: "ImageSlide",
  components: {
    LazyImg,
  },
  mixins: [SlideMixin],
  data() {
    return {
      error: false,
    };
  },
  computed: {
    previewUrl() {
      // TODO: Отключение ресайзера
      // TODO: Вычистить все старые ресайзеры
      // if (this.slide.type === SLIDE_TYPE.extimg) {
      //   return `${this.slide.path}${this.imgResizerParams}`;
      // }
      return makeUrl(this.slide.path);
    },
  },
};
</script>

<style scoped lang="less">
.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--image {
    flex: auto;
    width: 100%;
    height: 100%;
    max-height: 600px;
    margin: auto;
    vertical-align: middle;
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
    object-fit: cover;
    object-position: center;
  }

  &--error::after {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: "Font Awesome 5 Pro";
    font-size: 32px;
    color: #fff;
    content: "\f03e";
    background-color: rgba(212, 212, 212);
  }
}

.presentation-screen .container--image {
  object-fit: contain;
}
</style>
