<template>
  <div>
    <div v-if="currentUrl" :class="className">
      <div class="MediaPlayer__header">{{ currentError }}</div>
      <div class="MediaPlayer__frame">
        <media-player-image v-if="isImg" :url="currentUrl" />
        <iframe
          v-else
          :src="currentUrl"
          width="100%"
          height="100%"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import MediaPlayerImage from "./MediaPlayerImage";
import {
  isImageUrl,
  isVimeoUrl,
  isYoutubeUrl,
  MEDIA_PLAYER_EVENTS,
  REMOTE_CONTROL_MEDIA_KEYS,
} from "./MediaPlayerUtil";
import MediaPlayerEventBus from "./MediaPlayerEventBus";

const PLAYER_COMPONENT = {
  YOUTUBE: "media-player-youtube",
  VIMEO: "media-player-vimeo",
  IMAGE: "media-player-image",
};

export default {
  name: "MediaPlayer",
  components: { MediaPlayerImage },
  props: {
    url: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      currentError: "",
      isBlur: false,
    };
  },
  computed: {
    currentUrl: {
      cache: false,
      get() {
        return this.url;
      },
    },
    currentPlayerComponent() {
      const { currentUrl } = this;
      if (isImageUrl(currentUrl)) {
        return PLAYER_COMPONENT.IMAGE;
      }
      if (isYoutubeUrl(currentUrl)) {
        return PLAYER_COMPONENT.YOUTUBE;
      }
      if (isVimeoUrl(currentUrl)) {
        return PLAYER_COMPONENT.VIMEO;
      }
      return null;
    },
    className() {
      return {
        MediaPlayer: true,
        MediaPlayer_error: this.currentError,
        MediaPlayer_loading: this.isLoading,
        MediaPlayer_image: this.currentPlayerComponent === PLAYER_COMPONENT.IMAGE,
        MediaPlayer_youtube: this.currentPlayerComponent === PLAYER_COMPONENT.YOUTUBE,
        MediaPlayer_vimeo: this.currentPlayerComponent === PLAYER_COMPONENT.VIMEO,
      };
    },
    /**
     * Проверка на изображение.
     *
     * @returns {boolean} Является ли ссылка ссылкой на изображение.
     */
    isImg() {
      return isImageUrl(this.currentUrl);
    },
  },
  watch: {
    currentUrl() {
      this.isLoading = true;
    },
  },
  created() {
    this.sendStart = this.dispatchEvent.bind(this, MEDIA_PLAYER_EVENTS.PLAY);
    this.sendStop = this.dispatchEvent.bind(this, MEDIA_PLAYER_EVENTS.STOP);

    this.handleWindowFocus = () => {
      this.dispatchEvent(MEDIA_PLAYER_EVENTS.PAGE_FOCUS);
    };
    this.handleWindowBlur = () => {
      this.dispatchEvent(MEDIA_PLAYER_EVENTS.PAGE_BLUR);
    };
    this.handleKeyPress = e => {
      e = e || window.event;
      const key = e.keyCode || e.which;
      if (e && key) {
        switch (key) {
          case REMOTE_CONTROL_MEDIA_KEYS.START:
            this.sendStart();
            break;
          case REMOTE_CONTROL_MEDIA_KEYS.STOP:
            this.sendStop();
            break;
        }
      }
    };
  },
  mounted() {
    MediaPlayerEventBus.$on(MEDIA_PLAYER_EVENTS.CHANGE, () => {
      this.isLoading = true;
    });
    MediaPlayerEventBus.$on(MEDIA_PLAYER_EVENTS.READY, () => {
      this.isLoading = false;
    });
    MediaPlayerEventBus.$on(MEDIA_PLAYER_EVENTS.ERROR, error => {
      this.currentError = error;
    });

    if (typeof window !== "undefined") {
      window.addEventListener("focus", this.handleWindowFocus);
      window.addEventListener("blur", this.handleWindowBlur);
      window.addEventListener("keypress", this.handleKeyPress);
    }
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("focus", this.handleWindowFocus);
      window.removeEventListener("blur", this.handleWindowBlur);
      window.removeEventListener("keypress", this.handleKeyPress);
    }
  },
  methods: {
    dispatchEvent(event, payload = {}) {
      // @FIXME ui-refactoring remove event bus
      MediaPlayerEventBus.$emit(event, payload);
    },
  },
};
</script>

<style lang="less" scoped>
.MediaPlayer {
  overflow: hidden;
  position: relative;
  background-color: #000000;
  cursor: default;
  height: 100%;
  &_loading {
    opacity: 0.9;
  }
  &__frame {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    /**
     * !maybe can remove position: absolute
     * TODO: check for other width
     */
    @media (max-width: 640px) {
      position: static;
      height: 100vw;
    }
  }
}
</style>
