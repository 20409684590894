<template>
  <div class="container">
    <div v-if="isActive" class="container__timer">
      <span>{{ timer }}</span>
    </div>
    <ws-icon v-else class="container__icon" :class="iconClasses">clock</ws-icon>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { presentation, room } from "@/store/modules/store.namespaces";
import { GET_PRESENTATIONS, SET_SLIDE_TIME } from "@/store/modules/presentation/action-types";
import SlideMixin from "../../../event/room/Presentation/components/presentation-slider/presentation-slides/SlideMixin";

export default {
  name: "TimerSlide",
  mixins: [SlideMixin],
  props: {
    slide: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timer: "--:--:--",
      intervalTimer: null,
    };
  },
  computed: {
    ...mapState(room, ["roomNumber"]),
    ...mapState(presentation, ["currentPresentationInfo"]),
    needSetSlideTime() {
      return this.$route.matched[0].name !== "screenRoom";
    },
  },
  async created() {
    if (this.isActive) {
      if (this.needSetSlideTime) {
        await this.setSlideTime(this.slide.id);
      }
      await this.getPresentations();
    }
  },
  mounted() {
    if (this.isActive) {
      this.intervalTimer = setInterval(() => {
        this.countdown();
      }, 1000);
    }
  },
  beforeDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.timer = "00:00:00";
  },
  methods: {
    ...mapActions(presentation, {
      setSlideTime: SET_SLIDE_TIME,
      getPresentations: GET_PRESENTATIONS,
    }),
    countdown() {
      const seconds = moment.duration(this.slide?.timer).asSeconds();
      const start = moment(`${this.slide?.timer_start}+03:00`).add(seconds, "s");
      const now = moment();
      const diff = moment.duration(start.diff(now));
      if (+diff <= 0) {
        clearInterval(this.intervalTimer);
        this.timer = "00:00:00";
      } else {
        const hours = diff.get("h");
        const minutes = diff.get("m");
        const seconds = diff.get("s");
        this.timer = this.getTimer(hours, minutes, seconds);
      }
    },
    getTimer(hours, minutes, seconds) {
      return `${this.substituteZero(hours)}:${this.substituteZero(minutes)}:${this.substituteZero(
        seconds,
      )}`;
    },
    substituteZero(value) {
      return `${value}`.padStart(2, "0");
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 30px;
  text-align: center;

  &__timer {
    color: #f9ac44;
    font-size: 144px;
    font-weight: bold;
    line-height: 175px;
  }

  &__icon {
    font-size: 60px;
    color: #adc7db;

    &--size-s {
      font-size: 24px;
    }
  }
}
</style>
