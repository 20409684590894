<template>
  <div class="presentation-pinch-zoom">
    <div class="presentation-pinch-zoom__inner" :style="zoomStyle">
      <img v-show="imageSrc" alt="slide" class="presentation-pinch-zoom__image" :src="imageSrc" />
    </div>
  </div>
</template>

<script>
/**
  Official documentation of hammer.js:
    https://hammerjs.github.io/getting-started/
  Used examples: 
    https://bl.ocks.org/redgeoff/fe2e55fe710f341c335ba445569d1783
    https://bl.ocks.org/redgeoff/6be0295e6ebf18649966d48768398252
 */
const HammerImport = () => import(/* webpackChunkName="HammerJs" */ "@egjs/hammerjs");

export default {
  name: "PresentationSlidePinchZoom",
  props: {
    imageSrc: String,
  },
  data() {
    return {
      hammerInstance: null,
      scaleMin: 1,
      scaleMax: 5,
      scaleStep: 0.05,
      scale: 1,
    };
  },

  computed: {
    zoomStyle() {
      return {
        transform: `scale(${this.scale})`,
      };
    },
  },

  watch: {
    imageSrc(newVal, oldVal) {
      // reset zoom on content change
      if (newVal !== oldVal && this.hammerInstance) {
        this.resetZoom();
      }
    },
  },
  async mounted() {
    const { default: Hammer } = await HammerImport();
    this.hammerInstance = new Hammer(document.querySelector(".presentation-pinch-zoom"), {
      domEvents: true,
      touchAction: "pan-x pan-y",
    });
    ["doubletap", "pinch"].forEach(event => {
      this.hammerInstance.get(event).set({
        enable: true,
      });
    });

    this.hammerInstance.on("pinch", e => {
      this.zoom(e.scale);
    });

    this.hammerInstance.on("doubletap", () => {
      this.zoomExact(2);
    });

    this.hammerInstance.on("tap", () => {
      this.$emit("tap");
    });
  },

  beforeDestroy() {
    if (this.hammerInstance) {
      this.hammerInstance.destroy();
      this.hammerInstance = null;
    }
  },

  methods: {
    resetZoom() {
      this.scale = 1;
    },
    restrictZoom(scale) {
      if (scale < this.scaleMin) {
        return this.scaleMin;
      }
      if (scale > this.scaleMax) {
        return this.scaleMax;
      }
      return scale;
    },
    zoom(scaleBy) {
      this.scale = this.restrictZoom(this.scale + this.scaleStep * (scaleBy < 1 ? -1 : 1));
    },
    zoomExact(scaleBy) {
      this.scale = this.restrictZoom(this.scale * scaleBy);
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/_mixin";

.presentation-pinch-zoom {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;

  &__inner {
    position: relative;
    flex: 1 0 10%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform-origin: 0 0;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    .object-fit(contain);
  }
}
</style>
